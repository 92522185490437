export const allStates = [
    {id: `AK`, name: `Alaska`, optGroup: 'United States'},
    {id: `AB`, name: `Alberta`, optGroup: 'Canada'},
    {id: `AL`, name: `Alabama`, optGroup: 'United States'},
    {id: `AR`, name: `Arkansas`, optGroup: 'United States'},
    {id: `AZ`, name: `Arizona`, optGroup: 'United States'},
    {id: `BC`, name: `British Columbia`, optGroup: 'Canada'},
    {id: `CA`, name: `California`, optGroup: 'United States'},
    {id: `CO`, name: `Colorado`, optGroup: 'United States'},
    {id: `CT`, name: `Connecticut`, optGroup: 'United States'},
    {id: `DC`, name: `Dist. Of Columbia`, optGroup: 'United States'},
    {id: `DE`, name: `Delaware`, optGroup: 'United States'},
    {id: `FL`, name: `Florida`, optGroup: 'United States'},
    {id: `GA`, name: `Georgia`, optGroup: 'United States'},
    {id: `HI`, name: `Hawaii`, optGroup: 'United States'},
    {id: `IA`, name: `Iowa`, optGroup: 'United States'},
    {id: `ID`, name: `Idaho`, optGroup: 'United States'},
    {id: `IL`, name: `Illinois`, optGroup: 'United States'},
    {id: `IN`, name: `Indiana`, optGroup: 'United States'},
    {id: `KS`, name: `Kansas`, optGroup: 'United States'},
    {id: `KY`, name: `Kentucky`, optGroup: 'United States'},
    {id: `LA`, name: `Louisiana`, optGroup: 'United States'},
    {id: `MA`, name: `Massachusetts`, optGroup: 'United States'},
    {id: `MB`, name: `Manitoba`, optGroup: 'United States'},
    {id: `MD`, name: `Maryland`, optGroup: 'United States'},
    {id: `ME`, name: `Maine`, optGroup: 'United States'},
    {id: `MI`, name: `Michigan`, optGroup: 'United States'},
    {id: `MN`, name: `Minnesota`, optGroup: 'United States'},
    {id: `MO`, name: `Missouri`, optGroup: 'United States'},
    {id: `MS`, name: `Mississippi`, optGroup: 'United States'},
    {id: `MT`, name: `Montana`, optGroup: 'United States'},
    {id: `NB`, name: `New Brunswick`, optGroup: 'Canada'},
    {id: `NC`, name: `North Carolina`, optGroup: 'United States'},
    {id: `ND`, name: `North Dakota`, optGroup: 'United States'},
    {id: `NE`, name: `Nebraska`, optGroup: 'United States'},
    {id: `NF`, name: `Newfoundland`, optGroup: 'Canada'},
    {id: `NH`, name: `New Hampshire`, optGroup: 'United States'},
    {id: `NJ`, name: `New Jersey`, optGroup: 'United States'},
    {id: `NM`, name: `New Mexico`, optGroup: 'United States'},
    {id: `NS`, name: `Nova Scotia`, optGroup: 'Canada'},
    {id: `NT`, name: `Northwest Territories`, optGroup: 'Canada'},
    {id: `NU`, name: `Nunavut`, optGroup: 'Canada'},
    {id: `NV`, name: `Nevada`, optGroup: 'United States'},
    {id: `NY`, name: `New York`, optGroup: 'United States'},
    {id: `OH`, name: `Ohio`, optGroup: 'United States'},
    {id: `OK`, name: `Oklahoma`, optGroup: 'United States'},
    {id: `ON`, name: `Ontario`, optGroup: 'Canada'},
    {id: `OR`, name: `Oregon`, optGroup: 'United States'},
    {id: `PA`, name: `Pennsylvania`, optGroup: 'United States'},
    {id: `PE`, name: `Prince Edward Island`, optGroup: 'Canada'},
    {id: `QC`, name: `Quebec`, optGroup: 'Canada'},
    {id: `RI`, name: `Rhode Island`, optGroup: 'United States'},
    {id: `SC`, name: `South Carolina`, optGroup: 'United States'},
    {id: `SD`, name: `South Dakota`, optGroup: 'United States'},
    {id: `SK`, name: `Saskatchewan`, optGroup: 'Canada'},
    {id: `TN`, name: `Tennessee`, optGroup: 'United States'},
    {id: `TX`, name: `Texas`, optGroup: 'United States'},
    {id: `UT`, name: `Utah`, optGroup: 'United States'},
    {id: `VA`, name: `Virginia`, optGroup: 'United States'},
    {id: `VT`, name: `Vermont`, optGroup: 'United States'},
    {id: `WA`, name: `Washington`, optGroup: 'United States'},
    {id: `WI`, name: `Wisconsin`, optGroup: 'United States'},
    {id: `WV`, name: `West Virginia`, optGroup: 'United States'},
    {id: `WY`, name: `Wyoming`, optGroup: 'United States'},
    {id: `YT`, name: `Yukon`, optGroup: 'Canada'},
    {id: `**`, name: `Foreign Countries`, optGroup: 'Other'},
    {id: `AA`, name: `Armed Forces Americas`, optGroup: 'Other'},
    {id: `AE`, name: `Armed Forces`, optGroup: 'Other'},
    {id: `AP`, name: `Armed Forces Pacific`, optGroup: 'Other'},
    {id: `AS`, name: `American Samoa`, optGroup: 'Other'},
    {id: `CZ`, name: `Canal Zone`, optGroup: 'Other'},
    {id: `FM`, name: `Fed. States Of Micronesia`, optGroup: 'Other'},
    {id: `GU`, name: `Guam`, optGroup: 'Other'},
    {id: `MH`, name: `Marshall Islands`, optGroup: 'Other'},
    {id: `MX`, name: `Mexico`, optGroup: 'Other'},
    {id: `MP`, name: `Northern Mariana Is`, optGroup: 'Other'},
    {id: `PR`, name: `Puerto Rico`, optGroup: 'Other'},
    {id: `PW`, name: `Palau Island`, optGroup: 'Other'},
    {id: `VI`, name: `Virgin Islands`, optGroup: 'Other'},
];
